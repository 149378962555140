import {Controller} from "@hotwired/stimulus"

// An ease-out function that slows the count as it progresses
// returns a value from 0 to 1
const easeOutCubic = t => 1 - Math.pow(1 - t, 3);

// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;

// Connects to data-controller="animated-counter"
export default class extends Controller {

    static values = {
        duration: {type: Number, default: 2000},
        startFrom: {type: Number, default: 0},
        formatOptions: {type: Object},
        current: Number
    }

    connect() {
        this.element.dataset.value = this.startFromValue;
    }

    disconnect() {
        this._cancelAnimation();
    }

    currentValueChanged() {
        if (this.hasCurrentValue) {
            this._startAnimation();
        } else {
            this.element.textContent = "";
        }
    }

    _startAnimation() {
        const el = this.element
        const countFrom = el.dataset.value ?? 0;
        const delta = this.currentValue - countFrom;
        // Use that to calculate how many frames we need to complete the animation
        const totalFrames = Math.round(this.durationValue / frameDuration);
        const numberFormat = new Intl.NumberFormat(Locale.get(), this.formatOptionsValue);

        let frame = 0;
        this.currentAnimation = setInterval(() => {
            frame++;
            const progress = easeOutCubic(frame / totalFrames);
            // Use the progress value to calculate the current count
            const currentCount = countFrom + Math.round(delta * progress);

            // If the current count has changed, update the element
            if (el.dataset.value !== currentCount) {
                el.dataset.value = currentCount
                el.textContent = numberFormat.format(currentCount);
            }

            // If we’ve reached our last frame, stop the animation
            if (frame >= totalFrames) {
                clearInterval(this.currentAnimation);
                this.currentAnimation = null;
            }
        }, frameDuration)
    }

    _cancelAnimation() {
        if (this.currentAnimation) {
            clearInterval(this.currentAnimation);
            this.currentAnimation = null;
        }
    }
}
