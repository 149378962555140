import Locale from "./locale";
import dayjs from "dayjs";
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(updateLocale);

// Load locales
require("./locales/day");

dayjs.updateLocale('en', {
    formats: {
        // Force 24h format in default English
        LTS: 'H:mm:ss',
        LT: 'H:mm',
        L: "MM/DD/YYYY",
        LL: "MMMM D, YYYY",
        LLL: 'MMMM D, YYYY H:mm',
        LLLL: 'dddd, MMMM D, YYYY H:mm',
        l: "M/D/YYYY",
        ll: "MMM D, YYYY",
        lll: 'D MMM, YYYY H:mm',
        llll: 'ddd, MMM D, YYYY H:mm'
    }
});

Locale.addListener(function (locale) {
    let language, languageRegion;
    if (locale === "zh-Hans") {
        language = 'zh';
        languageRegion = 'zh-cn';
    } else if (locale === "zh-Hant") {
        language = 'zh';
        languageRegion = 'zh-tw';
    } else {
        let region;
        [language, region] = locale.split('-');
        if (region && region.length < 4) {
            languageRegion = [language, region].join('-').toLowerCase();
        } else {
            languageRegion = null;
        }
    }
    if (languageRegion && languageRegion in dayjs.Ls) {
        dayjs.locale(languageRegion);
    } else if (language in dayjs.Ls) {
        dayjs.locale(language);
    } else {
        console.warn(`Sorry! Cannot localize date / times into ${languageRegion}`)
        dayjs.locale('en');
    }
});