import {Controller} from "@hotwired/stimulus"
import {debounce} from "throttle-debounce";

const textualInputTypes = ['text', 'textarea', 'search', 'number'];

// Connects to data-controller="auto-submit"
export default class extends Controller {
    static targets = ['form', 'submitter']
    static values = {
        debounce: {type: Number, default: 200}
    }

    connect() {
        let form = this._getForm();

        this._changeListener = this._changeListener.bind(this, form);
        this._resetListener = this._resetListener.bind(this, form);
        this._submitListener = this._submitListener.bind(this, form);

        form.addEventListener('submit', this._submitListener);
        this._addInputListeners(form);

        // Use mutation observer to monitor changes to form and add event listeners to new inputs
        this.formObserver = new MutationObserver((mutationList) => this._mutationHandler(form, mutationList));
        this.formObserver.observe(form, {attributes: false, childList: true, subtree: true});
    }

    disconnect() {
        super.disconnect();
        this.formObserver?.disconnect();
    }

    submit(form) {
        if (form === undefined) {
            form = this._getForm();
        }
        if (this.hasSubmitterTarget) {
            if (/Apple Computer/.test(navigator.vendor)) {
                // requestSubmit(submitter) does not work in safari
                this.submitterTarget.click();
            } else {
                form.requestSubmit(this.submitterTarget);
            }
        } else {
            form.requestSubmit();
        }
    }

    _getForm() {
        let form = this.hasFormTarget ? this.formTarget : this.element;
        if (form == null || form.nodeName !== 'FORM') {
            throw this.hasFormTarget
                ? 'The form target must be a FORM node'
                : 'Element must be a FORM node, or the form should be marked with data-auto-submit-target="form"'
        }
        return form;
    }

    _addInputListeners(form) {
        form.querySelectorAll('input,select').forEach(input => {
            this._addInputListener(form, input);
        });
        form.querySelectorAll('button[type=reset]').forEach(resetButton => {
            resetButton.addEventListener('click', this._resetListener);
        });

    }

    _addInputListener(form, input) {
        let debounceMs = this.debounceValue,
            debouncedSubmitter = debounceMs && debounceMs > 0 ? debounce(debounceMs, this._changeListener) : this._changeListener;

        let name = input.getAttribute('name'), type = input.getAttribute('type');
        if (name && name.length && !input.getAttribute('data-no-autosubmit')) {
            if (textualInputTypes.includes(type)) {
                // Also listen to input events for textual fields to respond to keystrokes.
                // Use 500 ms as the minimum debounce delay
                input.addEventListener('input', debounceMs < 500 ? debounce(500, this._changeListener) : debouncedSubmitter);
            }
            input.addEventListener('change', debouncedSubmitter);
        }
    }

    _changeListener(form) {
        this.submit(form);
    }

    _resetListener(form, event) {
        form.reset();
        this.submit(form);
        event.preventDefault();
        return false;
    }

    _submitListener(form, event) {
        let submittingData = new URLSearchParams(new FormData(form)).toString();
        if (!event.submitter && submittingData === this.submittedData) {
            event.preventDefault();
            return false;
        } else {
            this.submittedData = submittingData;
        }
    }

    _mutationHandler(form, mutationList) {
        let shouldSubmit = false;
        for (let mutation of mutationList) {
            if (mutation.type === 'childList' && mutation.addedNodes) {
                let handler = input => {
                    shouldSubmit = !input.disabled && input.name.length > 0;
                    this._addInputListener(form, input);
                };
                for (let node of mutation.addedNodes) {
                    if (node.nodeName === 'INPUT' || node.nodeName === 'SELECT') {
                        handler(node);
                    } else if (node.querySelectorAll) {
                        node.querySelectorAll('input,select').forEach(handler);
                    }
                }
            }
            if (!shouldSubmit && mutation.removedNodes) {
                let handler = input => shouldSubmit = !input.disabled && input.name.length > 0;
                // Check if any inputs have been removed
                for (let node of mutation.removedNodes) {
                    if (node.nodeName === 'INPUT' || node.nodeName === 'SELECT') {
                        handler(node);
                    } else if (node.querySelectorAll) {
                        node.querySelectorAll('input,select').forEach(handler);
                    }
                    if (shouldSubmit) {
                        break;
                    }
                }
            }
        }
        if (shouldSubmit) {
            this.submit(form);
        }
    }
}
