import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n";

// Connects to data-controller="transport-plan"
export default class extends Controller {
    static targets = [
        'transportTypeInput', 'loadSelectionRules', 'pigCategoryInputs', 'pigSexInputs',
        'selectRulesWrapper', 'selectRules', 'selectRulesCounter', 'selectRuleEnabledInput',
        'excludeRulesWrapper', 'excludeRules', 'excludeRulesCounter', 'excludeRuleEnabledInput',
        'ensureRulesWrapper', 'ensureRules', 'ensureRulesCounter', 'ensureRuleEnabledInput',
        'commit'
    ]
    static values = {
        cullingEnabled: {type: Boolean}
    }

    connect() {
        this.element.addEventListener('submit', () => {
            this.commitTarget.disabled = false;
        });
        this.element.requestSubmit();
    }

    transportTypeInputTargetConnected(el) {
        el.addEventListener('change', () => this.loadSelectionRulesTarget.disabled = el.value === "")
        if (this.hasLoadSelectionRulesTarget) {
            this.loadSelectionRulesTarget.disabled = el.value === "";
        }
    }

    selectRuleEnabledInputTargetConnected(el) {
        el.addEventListener('change', () => this._updateSelectRuleCounter());
        this._updateSelectRuleCounter();
    }

    excludeRuleEnabledInputTargetConnected(el) {
        el.addEventListener('change', () => this._updateExcludeRuleCounter());
        this._updateExcludeRuleCounter();
    }

    ensureRuleEnabledInputTargetConnected(el) {
        el.addEventListener('change', () => this._updateEnsureRuleCounter());
        this._updateEnsureRuleCounter();
    }

    commitTargetConnected(el) {
        el.addEventListener('click', () => {

        });
    }

    loadSelectionRules() {
        const transportType = this.transportTypeInputTarget.value;
        if (transportType == null || transportType.length === 0) {
            return;
        }

        switch (transportType) {
            case 'weaning':
                this.setPigCategory('porker');
                this.setPigSex("both");
                this._setSelectionRules(this.selectRulesWrapperTarget, {
                    age: {
                        value: 21,
                        comparator: 'gt'
                    }
                });
                this._setSelectionRules(this.ensureRulesWrapperTarget, {
                    age: {
                        value: 28,
                        comparator: 'gt'
                    }
                });
                this._setSelectionRules(this.excludeRulesWrapperTarget, {});
                break;
            case 'nursery':
                this.setPigCategory('porker');
                this.setPigSex("both");
                this._setSelectionRules(this.selectRulesWrapperTarget, {
                    weight: {
                        value: {metric: 25, imperial: 55},
                        comparator: 'gt'
                    },
                    age: {
                        value: 50,
                        comparator: 'gt'
                    }
                });
                this._setSelectionRules(this.ensureRulesWrapperTarget, [{
                    weight: {
                        value: {metric: 30, imperial: 65},
                        comparator: 'gt'
                    }
                }, {
                    age: {
                        value: 90,
                        comparator: 'gt'
                    }
                }]);
                this._setSelectionRules(this.excludeRulesWrapperTarget, {});
                break;
            case 'slaughter':
                this.setPigCategory('porker');
                this.setPigSex("both");
                this._setSelectionRules(this.selectRulesWrapperTarget, {
                    weight: {
                        value: {metric: 110, imperial: 250},
                        comparator: 'gt'
                    },
                    age: {
                        value: 140,
                        comparator: 'gt'
                    }
                });
                this._setSelectionRules(this.ensureRulesWrapperTarget, [{
                    weight: {
                        value: {metric: 120, imperial: 270},
                        comparator: 'gt'
                    }
                }, {
                    age: {
                        value: 210,
                        comparator: 'gt'
                    }
                }]);
                this._setSelectionRules(this.excludeRulesWrapperTarget, {});
                break;
            case 'slaughter_sows':
                this.setPigCategory('breeding');
                this.setPigSex("female");
                if (this.cullingEnabledValue) {
                    this._setSelectionRules(this.selectRulesWrapperTarget, [{
                        cullReason: true
                    }, {
                        parity: {
                            value: 10,
                            comparator: 'gt'
                        }
                    }]);
                } else {
                    this._setSelectionRules(this.selectRulesWrapperTarget, {
                        parity: {
                            value: 10,
                            comparator: 'gt'
                        }
                    });
                }
                this._setSelectionRules(this.ensureRulesWrapperTarget, {});
                this._setSelectionRules(this.excludeRulesWrapperTarget, {});
                break;
            case 'breeding_gilts':
                this.setPigCategory('breeding');
                this.setPigSex("female");
                this._setSelectionRules(this.selectRulesWrapperTarget, {
                    age: {
                        value: 200,
                        comparator: 'gt'
                    },
                    parity: {
                        value: 1,
                        comparator: 'lt'
                    },
                });
                this._setSelectionRules(this.ensureRulesWrapperTarget, {
                    age: {
                        value: 245,
                        comparator: 'gt'
                    },
                    parity: {
                        value: 1,
                        comparator: 'lt'
                    },
                });
                this._setSelectionRules(this.excludeRulesWrapperTarget, {
                    cullReason: true
                });
                break;
            case 'breeding_boars':
                this.setPigCategory('breeding');
                this.setPigSex("male");
                this._setSelectionRules(this.selectRulesWrapperTarget, {
                    age: {
                        value: 200,
                        comparator: 'gt'
                    },
                });
                this._setSelectionRules(this.ensureRulesWrapperTarget, {
                    age: {
                        value: 299,
                        comparator: 'gt'
                    }
                });
                this._setSelectionRules(this.excludeRulesWrapperTarget, {
                    cullReason: true
                });
                break;
            default:
                console.warn("transportType not implemented: ", transportType)
        }

        this.updateRuleCounters();
    }

    updateRuleCounters() {
        this._updateSelectRuleCounter();
        this._updateEnsureRuleCounter();
        this._updateExcludeRuleCounter();
    }

    setPigCategory(category) {
        if (category == null || category === "all") {
            category = "";
        }
        this.pigCategoryInputsTarget.querySelectorAll('input[type=radio]').forEach((input) => {
            input.checked = input.value === category
        });
    }

    setPigSex(sex) {
        if (sex == null || sex === "both") {
            sex = "";
        }
        this.pigSexInputsTarget.querySelectorAll('input[type=radio]').forEach((input) => {
            input.checked = input.value === sex
        });
    }

    _setSelectionRules(wrapper, options) {
        if (!Array.isArray(options)) {
            options = [options];
        }
        const nestedEnsureFieldsController = this.application.getControllerForElementAndIdentifier(
                wrapper,
                'nested-form'
            ),
            groupCount = options.length,
            recordTargets = nestedEnsureFieldsController.recordTargets.filter(record => !record.dataset.deleted);

        if (recordTargets.length < groupCount) {
            const recordsToAdd = groupCount - recordTargets.length;
            // Add the necessary amount of groups
            for (let i = 0; i < recordsToAdd; i++) {
                nestedEnsureFieldsController.addRecord();
            }
            // Wait for stimulus to load the controller(s)
            setTimeout(() => this._setSelectionRules(wrapper, options), 1);
        } else {
            if (recordTargets.length > groupCount) {
                const recordsToRemove = recordTargets.length - groupCount;
                // Remove extra groups
                for (let i = 0; i < recordsToRemove; i++) {
                    nestedEnsureFieldsController.removeRecord(recordTargets.at(-1 - i));
                }
            }
            options.forEach((groupOptions, index) => {
                const controller = this.application.getControllerForElementAndIdentifier(
                    recordTargets[index],
                    'transport-plan-selection-rule'
                );
                if (controller) {
                    controller.configure(groupOptions);
                } else {
                    console.error("No selection rule controller found in ", element);
                }
            });
        }
    }

    _updateSelectRuleCounter() {
        const count = this.selectRuleEnabledInputTargets.filter(el => el.checked).length;
        this.selectRulesCounterTarget.classList.toggle('badge-light', count === 0);
        this.selectRulesCounterTarget.classList.toggle('badge-leeo', count !== 0);
        this.selectRulesCounterTarget.textContent = `${count} ${i18n.t('activerecord.models.transport_plan_selection_rule', {count: count})}`;
    }

    _updateExcludeRuleCounter() {
        const count = this.excludeRuleEnabledInputTargets.filter(el => el.checked).length;
        this.excludeRulesCounterTarget.classList.toggle('badge-light', count === 0);
        this.excludeRulesCounterTarget.classList.toggle('badge-leeo', count !== 0);
        this.excludeRulesCounterTarget.textContent = `${count} ${i18n.t('activerecord.models.transport_plan_selection_rule', {count: count})}`;
    }

    _updateEnsureRuleCounter() {
        const count = this._countEnabledRules(this.ensureRuleEnabledInputTargets);
        this.ensureRulesCounterTarget.classList.toggle('badge-light', count === 0);
        this.ensureRulesCounterTarget.classList.toggle('badge-leeo', count !== 0);
        this.ensureRulesCounterTarget.textContent = `${count} ${i18n.t('activerecord.models.transport_plan_selection_rule', {count: count})}`;
    }

    _countEnabledRules(elements) {
        return elements.filter(el => {
            const nestedFormRecord = el.closest('[data-nested-form-target=record]')
            return !nestedFormRecord.dataset.deleted && el.checked;
        }).length
    }
}
