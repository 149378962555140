import {getTimeZones, timeZonesNames} from "@vvo/tzdb";

// The tzdb API is a bit more complex than I would like, trying to abstract the complexity away.

const priorityTimeZones = [ // Main time zones in key countries
    "America/Adak",
    "Pacific/Honolulu",
    "America/Anchorage",
    "America/Los_Angeles",
    "America/Tijuana",
    "America/Vancouver",
    "America/Hermosillo",
    "America/Edmonton",
    "America/Ciudad_Juarez",
    "America/Denver",
    "America/Phoenix",
    "America/Whitehorse",
    "America/Chicago",
    "America/Mexico_City",
    "America/Matamoros",
    "America/Regina",
    "America/Winnipeg",
    "America/Rio_Branco",
    "America/Atikokan",
    "America/Cancun",
    "America/New_York",
    "America/Toronto",
    "America/Manaus",
    "America/Blanc-Sablon",
    "America/Halifax",
    "America/St_Johns",
    "America/Sao_Paulo",
    "America/Noronha",
    "Atlantic/Azores",
    "Europe/Dublin",
    "Europe/London",
    "Atlantic/Canary",
    "Europe/Lisbon",
    "Europe/Amsterdam",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Budapest",
    "Europe/Copenhagen",
    "Europe/Ljubljana",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Paris",
    "Europe/Prague",
    "Europe/Rome",
    "Europe/Stockholm",
    "Europe/Vienna",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zurich",
    "Europe/Athens",
    "Europe/Bucharest",
    "Europe/Helsinki",
    "Europe/Kaliningrad",
    "Europe/Riga",
    "Europe/Sofia",
    "Europe/Tallinn",
    "Europe/Vilnius",
    "Europe/Moscow",
    "Europe/Samara",
    "Asia/Yekaterinburg",
    "Asia/Urumqi",
    "Asia/Omsk",
    "Asia/Bangkok",
    "Asia/Ho_Chi_Minh",
    "Asia/Phnom_Penh",
    "Asia/Novosibirsk",
    "Asia/Shanghai",
    "Asia/Irkutsk",
    "Asia/Manila",
    "Asia/Taipei",
    "Asia/Tokyo",
    "Asia/Seoul",
    "Asia/Chita",
    "Asia/Vladivostok",
    "Asia/Sakhalin",
    "Asia/Kamchatka"]

export default class TimeZoneDatabase {

    static getPriorityTimeZoneIds() {
        return priorityTimeZones
    }

    static getAllTimeZones() {
        // TZDB groups all time zones with the same offset, we want to show all of the IDs so we have to ungroup it.
        const groupedTimeZones = getTimeZones({includeUtc: true});
        // List all time zones which have a "root" item in groupedTimeZones
        const rootTimeZoneIds = groupedTimeZones.map(tz => tz.name);
        // Ungroup the timezones
        return groupedTimeZones.flatMap(timeZone => {
            // The group array contains both active and deprecated time zone ids.
            // List all items in #group that are either:
            //  - The main time zone (timeZoneId === timeZone.name)
            //  - Do not have an main item in +groupedTimeZones+ and are not deprecated
            //  - The UTC timezone ("Etc/UTC")
            const activeTimeZoneIds = timeZone.group.filter(timeZoneId => {
                return timeZoneId === timeZone.name || timeZoneId === "Etc/UTC" ||
                    (!rootTimeZoneIds.includes(timeZoneId) && timeZonesNames.includes(timeZoneId))
            });
            // Duplicate the timeZone object for each item in activeTimeZoneIds
            return activeTimeZoneIds.map(timeZoneId => {
                // Make the time zone id more user friendly "America/Los_Angeles" => "America, Los Angeles"
                const friendlyName = timeZoneId.replaceAll("/", ", ").replaceAll("_", " ");

                // List the timeZones from group that do not have a "root" time zone
                const keywords = new Set(
                    timeZone.group
                        .filter(timeZoneId => {
                            return timeZoneId === timeZone.name || !rootTimeZoneIds.includes(timeZoneId)
                        })
                        .map(timeZoneId => {
                            // "America/Los_Angeles" => "Los Angeles"
                            return timeZoneId.split('/').at(-1).replaceAll("_", " ")
                        })
                );
                // Add some extra keywords
                switch (timeZoneId) {
                    case "Etc/UTC":
                        keywords.add("GMT")
                        keywords.add("Zulu")
                        keywords.add("Greenwich main time")
                        break
                    case "America/Mexico_City":
                        keywords.add("Guadalajara")
                        break
                    case "America/Guatemala":
                        keywords.add("Central America")
                        break
                    case "America/Lima":
                        keywords.add("Quito")
                        break
                    case "America/Sao_Paulo":
                        keywords.add("Brasilia")
                        break
                    case "Atlantic/South_Georgia":
                        keywords.add("Mid-Atlantic")
                        break
                    case "Europe/London":
                        keywords.add("Edinburgh")
                        break
                    case "Europe/Zurich":
                        keywords.add("Bern")
                        break
                    case "Africa/Algiers":
                        keywords.add("West Central Africa")
                        break
                    case "Africa/Johannesburg":
                        keywords.add("Pretoria")
                        break
                    case "Europe/Moscow":
                        keywords.add("St. Petersburg")
                        break
                    case "Asia/Muscat":
                        keywords.add("Abu Dhabi")
                        break
                    case "Asia/Karachi":
                        keywords.add("Islamabad")
                        break
                    case "Asia/Kolkata":
                        keywords.add("Chennai")
                        keywords.add("New Delhi")
                        break
                    case "Asia/Dhaka":
                        keywords.add("Astana")
                        break
                    case "Asia/Colombo":
                        keywords.add("Sri Jayawardenepura")
                        break
                    case "Asia/Bangkok":
                        keywords.add("Hanoi")
                        break
                    case "Asia/Shanghai":
                        keywords.add("Beijing")
                        break
                    case "Asia/Tokyo":
                        keywords.add("Osaka")
                        keywords.add("Sapporo")
                        break
                    case "Pacific/Auckland":
                        keywords.add("Wellington")
                        break
                    case "Pacific/Tongatapu":
                        keywords.add("Nuku'alofa")
                        break
                }

                return {
                    id: timeZoneId,
                    name: friendlyName,
                    alternativeName: timeZone.alternativeName,
                    abbreviation: timeZone.abbreviation,
                    currentTimeFormat: timeZone.currentTimeFormat,
                    countryCode: timeZone.countryCode ?? null,
                    keywords: keywords.size ? [...keywords] : null,
                    priority: priorityTimeZones.includes(timeZoneId),
                }
            });
        });
    }
}
