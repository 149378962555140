import {Controller} from "@hotwired/stimulus"
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'

// Connects to data-controller="local-time"
export default class extends Controller {
    static values = {
        iso8601: String,
        timeZone: String,
        format: String,
        relative: {type: Boolean, default: false}
    }

    initialize() {
        dayjs.extend(relativeTime);
        dayjs.extend(utc);
        dayjs.extend(timezone);
    }

    disconnect() {
        if (this.currentTimer) {
            clearTimeout(this.currentTimer)
        }
    }

    timeZoneValueChanged() {
        this._formatTimestamp(this.element, this._currentValue())
    }

    iso8601ValueChanged() {
        this._formatTimestamp(this.element, this._currentValue())
    }

    relativeValueChanged() {
        this._formatTimestamp(this.element, this._currentValue())
    }

    _currentValue() {
        if (this.hasIso8601Value) {
            return dayjs(this.iso8601Value);
        } else {
            return dayjs();
        }
    }

    _formatTimestamp(element, timestamp) {
        if (this.currentTimer) {
            clearTimeout(this.currentTimer)
        }
        if (this.hasTimeZoneValue) {
            timestamp = timestamp.tz(this.timeZoneValue);
        }
        const format = this.hasFormatValue ? this.formatValue : 'LLL';
        if (this.hasIso8601Value && this.relativeValue && timestamp.isAfter(dayjs().subtract(7, 'days'))) {
            element.textContent = timestamp.fromNow();
            element.setAttribute('title', timestamp.format(format))
            // Refresh the timestamp when it becomes stale
            let ago = timestamp.diff(dayjs(), 's');
            if (ago < 60) {
                this.currentTimer = setTimeout(() => this._formatTimestamp(element, timestamp), 1000);
            } else if (ago < 3600) {
                this.currentTimer = setTimeout(() => this._formatTimestamp(element, timestamp), 60_000);
            } else {
                this.currentTimer = setTimeout(() => this._formatTimestamp(element, timestamp), 900_000);
            }
        } else {
            element.textContent = timestamp.format(format);
            element.removeAttribute('title');
            if (!this.hasIso8601Value) {
                // Refresh the timestamp when the next minute starts
                this.currentTimer = setTimeout(() => this._formatTimestamp(element, this._currentValue()), (60 - timestamp.second()) * 1000);
            }
        }
    }
}
