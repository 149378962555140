import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="text-area-helper"
export default class extends Controller {
    static targets = ['input', 'remaining']
    static values = {
        submitOn: String,
        submitEmpty: {type: Boolean, default: true}
    }

    connect() {
        if (this.hasSubmitOnValue) {
            let input = this._getInput()
            input.addEventListener('keydown', (ev) => {
                if (ev.keyCode === 13) {
                    let metaKey = event.ctrlKey || event.metaKey || event.shiftKey;
                    switch (this.submitOnValue) {
                        case 'enter':
                            if (!metaKey) {
                                ev.preventDefault();
                                if (this.submitEmptyValue || input.value.match(/\S+/)) {
                                    input.form.requestSubmit();
                                }
                            }
                            break;
                        case 'metaEnter':
                            if (metaKey) {
                                ev.preventDefault();
                                if (this.submitEmptyValue || input.value.match(/\S+/)) {
                                    input.form.requestSubmit();
                                }
                            }
                            break;
                    }
                }
            });
        }
    }

    remainingTargetConnected(el) {
        let input = this._getInput()
        let maxLength = input.getAttribute('maxlength');
        if (maxLength && maxLength.length > 0) {
            this.remainingTarget.textContent = `${input.value.length}/${maxLength}`
            input.addEventListener('input', () => {
                this.remainingTarget.textContent = `${input.value.length}/${maxLength}`
            })
        } else {
            console.warn("[TextAreaHelper] Remaining target is set on element without 'maxlength'");
        }
    }

    _getInput() {
        let input = this.hasInputTarget ? this.inputTarget : this.element;
        if (input.tagName !== 'TEXTAREA') {
            if (this.hasInputTarget) {
                console.error("[TextAreaHelper] Input is not a textarea: ", input);
            } else {
                console.error("[TextAreaHelper] Element is not a textarea. Set the textarea as a target with 'data-text-area-helper-target=\"input\"'");
            }
        }
        return input;
    }
}
