import {Controller} from "@hotwired/stimulus";
import Locale from "../locale";
import {easepick, AmpPlugin, RangePlugin, LockPlugin, DateTime} from "@easepick/bundle";
import dayjs from "dayjs";

export default class extends Controller {
    static values = {
        calendars: {type: Number, default: 1},
        grid: {type: Number, default: 1},
        firstDate: {type: Number, default: 1},
        inline: {type: Boolean, default: false},
        past: {type: Boolean, default: true},
        future: {type: Boolean, default: true},
        selectRange: {type: Boolean, default: true},
        maxRange: {type: Number, default: 500},
        showDropdowns: Boolean,
        minDate: String,
        maxDate: String,
        shadow: String,
        readonly: Boolean
    }

    connect() {
        const calendars = this.calendarsValue,
            minDate = this.hasMinDateValue ? this.minDateValue : (this.pastValue ? '2014-01-01' : dayjs().format('YYYY-MM-DD')),
            maxDate = this.hasMaxDateValue ? this.maxDateValue : (this.futureValue ? dayjs().add(3, 'y').endOf('year') : dayjs()).format('YYYY-MM-DD'),
            date = this.parseValue(this.element.value),
            options = {
                element: this.element,
                css: [this.data.get("css")],
                lang: Locale.get(),
                calendars: calendars,
                grid: this.gridValue,
                firstDay: this.firstDateValue,
                inline: this.inlineValue,
                readonly: this.hasReadonlyValue ? this.readonlyValue : undefined,
                date: date,
                plugins: [],
            };

        options.plugins.push(LockPlugin);
        options['LockPlugin'] = {
            minDate: minDate,
            maxDate: maxDate,
        }

        let showDropdowns;
        if (this.hasShowDropdownsValue) {
            showDropdowns = this.showDropdownsValue;
        } else {
            showDropdowns = dayjs(maxDate).diff(minDate, 'month') > 1
        }
        if (showDropdowns) {
            options.plugins.push(AmpPlugin)
            options['AmpPlugin'] = {
                dropdown: {
                    years: dayjs(minDate).year() !== dayjs(maxDate).year(),
                    // When showing multiple calendars, every dropdown controls the first calendar which is confusing.
                    months: calendars === 1,

                    minYear: dayjs(minDate).year(),
                    maxYear: this.hasMaxDateValue ? dayjs(maxDate).year() : dayjs().year() + 1
                },
                darkMode: false
            }
        }

        if (this.selectRangeValue) {
            options.plugins.push(RangePlugin);
            options['RangePlugin'] = {tooltip: true};
            options['LockPlugin']['maxDays'] = this.maxRangeValue;
        }

        this.picker = new easepick.create(options);
        if (calendars > 1) {
            // When showing multiple calendars, make sure we don't show a bunch of disabled months.
            let firstDate = dayjs(maxDate.toJSDate()).startOf('month').subtract(calendars - 1, 'months');
            if (firstDate.isBefore()) {
                this.picker.gotoDate(new DateTime(firstDate.toDate()));
            }
        }

        this.picker.on('select', () => {
            this.element.dispatchEvent(new Event('change', {bubbles: true}));
        });

        this.picker.on('render', (e) => {
            if (this.hasShadowValue) {
                e.target.classList.add('shadow-' + this.shadowValue);
            } else if (this.inlineValue) {
                e.target.classList.add('border', 'shadow-none');
            }
        });
        if (this.inlineValue) {
            // Re-render
            this.picker.renderAll();
        }

        if (this.data.has('cycleDate') && this.data.has('cycleDuration')) {
            const cycleDate = new DateTime(this.data.get('cycleDate')), cycleDuration = this.data.get('cycleDuration');
            this.picker.on('view', (evt) => {
                const {view, date, target} = evt.detail;
                if (view === 'CalendarDay' && date.diff(cycleDate, 'days') % cycleDuration === 0) {
                    target.classList.add('working-cycle-start');
                }
            });
        }
    }

    disconnect() {
        this.picker.destroy();
    }

    parseValue(value) {
        if (value == null || value.length === 0) {
            return null
        } else {
            let date = dayjs(value)
            if (date.isValid()) {
                return date.format('YYYY-MM-DD')
            } else {
                console.warn(`${value} cannot be parsed to a date`)
                return null
            }
        }
    }
}