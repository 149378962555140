import {Controller} from "@hotwired/stimulus";
import Fuse from "fuse.js";

export default class extends Controller {
    static targets = ["searchInput", "itemsContainer", "itemsCategory", "itemsCategoryHeader", "item", "nothingFound"];

    connect() {
        let fuseItems = [],
            fuseOptions = {
                id: 'type',
                shouldSort: true,
                threshold: 0.3,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [{
                    name: 'title',
                    weight: 0.5
                }, {
                    name: 'keywords',
                    weight: 0.35
                }, {
                    name: 'description',
                    weight: 0.15
                }]
            }
        this.itemTargets.forEach((itemEl) => {
            const type = itemEl.getAttribute('data-search-filter-item-type');
            if (type && type.length) {
                fuseItems.push({
                    type: type,
                    title: itemEl.querySelector('[data-search-filter-item-target="title"]').textContent,
                    description: itemEl.querySelector('[data-search-filter-item-target="description"]').textContent,
                    keywords: itemEl.getAttribute('data-search-filter-item-keywords')?.split(',')
                });
            } else {
                console.error(itemEl, "does not have a type defined in 'data-search-filter-item-type'")
            }
        });
        this.fuse = new Fuse(fuseItems, fuseOptions);
    }

    filterItems() {
        let filter = this.searchInputTarget.value;
        if (filter.length === 0) {
            this.itemsCategoryHeaderTargets.forEach(el => el.classList.remove('d-none'));
            this.itemTargets.forEach(el => el.classList.remove('d-none'));
            this.nothingFoundTarget.classList.add('d-none');
        } else {
            let result = this.fuse.search(filter), foundAnything = false;
            this.itemTargets.forEach((itemEl) => {
                if (result.includes(itemEl.getAttribute('data-search-filter-item-type'))) {
                    itemEl.classList.remove('d-none');
                    foundAnything = true;
                } else {
                    itemEl.classList.add('d-none');
                }
            });
            this.itemsCategoryTargets.forEach(el => {
                const header = el.querySelector('[data-search-filter-target="itemsCategoryHeader"]'),
                    containsItems = header && foundAnything && el.querySelector('[data-search-filter-target="item"]:not(.d-none)') != null;
                if (header) {
                    header.classList.toggle('d-none', !containsItems);
                }
            });

            this.nothingFoundTarget.classList.toggle('d-none', foundAnything);
        }
    }
}