import AutocompleteSource from './autocomplete_source'
import i18n from "../../i18n";

const createIdentifierIconElement = function (pig) {
    if (pig == null) {
        return null;
    }
    const icon = document.createElement("i");
    icon.classList.add('fa');
    if (pig.current_code && pig.current_code.length) {
        icon.classList.add('fa-tag');
    } else if (pig.breed_registry_code && pig.breed_registry_code.length) {
        icon.classList.add('fa-book');
    } else {
        icon.classList.add('fa-dot-circle');
    }
    return icon;
};

const createPigTypeIconElement = function (pig) {
    if (pig == null || pig.type == null) {
        return null;
    }
    const icon = document.createElement("i");
    icon.classList.add('fa');
    switch (pig.type) {
        case 'breeding_female':
        case 'breeding_sow':
        case 'breeding_gilt':
            icon.classList.add('fa-venus', 'text-leeo');
            break
        case 'breeding_male':
            icon.classList.add('fa-mars', 'text-leeo');
            break
        case 'neutered_female':
            icon.classList.add('fa-neuter', 'text-female-pink');
            break
        case 'neutered_male':
            icon.classList.add('fa-neuter', 'text-male-blue');
            break
        case 'female':
            icon.classList.add('fa-venus', 'text-female-pink');
            break
        case 'male':
            icon.classList.add('fa-mars', 'text-male-blue');
            break
        default:
            icon.classList.add('fa-questionmark', 'text-muted');
    }
    return icon;
}

const createCurrentPenElement = function (pig) {
    if (!pig.current_pen) {
        return null;
    }

    const icon = document.createElement("i"),
        result = document.createElement("span");
    icon.classList.add('fa', 'fa-inbox', 'fa-sm', 'mr-1');
    result.classList.add('small', 'text-muted');
    result.append(icon);
    result.append(pig.current_pen.name);
    return result;
}

const createPigAgeElement = function (pig) {
    if (!pig.age || pig.age >= 200) {
        return null;
    }

    const icon = document.createElement("i"),
        result = document.createElement("span");
    icon.classList.add('fa', 'fa-calendar', 'fa-sm', 'mr-1');
    result.classList.add('small', 'text-muted');
    result.append(icon);
    result.append(pig.age);
    return result;
}

export default class PigAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('pigs', {keys: ['short_identifier'], ...options});
    }

    styleOptionNode(node, selection) {
        node.classList.add('d-flex', 'align-items-center');
        node.innerHTML = `<div class="text-wrap flex-fill">${selection.match}</div>`;

        const identifierIcon = createIdentifierIconElement(selection.value),
            typeIcon = createPigTypeIconElement(selection.value),
            penSection = createCurrentPenElement(selection.value),
            ageSection = createPigAgeElement(selection.value),
            typeSection = document.createElement("span");
        identifierIcon.classList.add('fa-sm', 'mr-2');
        node.prepend(identifierIcon);

        if (penSection) {
            penSection.classList.add('ml-3');
            node.append(penSection);
        }
        if (ageSection) {
            ageSection.classList.add('ml-2');
            node.append(ageSection);
        }

        typeIcon.classList.add('mr-1');
        typeSection.classList.add('small', 'text-muted', 'ml-2');
        typeSection.append(typeIcon);
        typeSection.append(i18n.t(`pig_type.${selection.value.type}`, {count: 1}))
        node.append(typeSection);
    }

    styleFormattedValue(node, selection) {
        const icon = createPigTypeIconElement(selection.value),
            penSection = createCurrentPenElement(selection.value);

        icon.classList.add('mr-2');
        node.prepend(icon);

        if (penSection) {
            penSection.classList.add('ml-3');
            node.append(penSection);
        }
    }
}
