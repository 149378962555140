import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n";

const fileExtensionRegex = /.+(\.[a-z0-9]+)$/i

// Connects to data-controller="file-upload"
export default class extends Controller {
    static values = {
        current: String
    }

    connect() {
        let dropzone, dropzoneMessage;
        if (this.element.parentElement && this.element.parentElement.classList.contains('file-dropzone')) {
            dropzone = this.element.parentElement;
            dropzoneMessage = dropzone.querySelector('.dropzone-message');
        } else {
            dropzone = document.createElement('section');
            dropzone.className = 'file-dropzone';
            this.element.replaceWith(dropzone);
            dropzone.append(this.element);
            dropzoneMessage = document.createElement('section');
            dropzoneMessage.className = 'dropzone-message text-truncate';
            dropzoneMessage.textContent = i18n.t('js.file_input.drag_drop_instruction');
            dropzone.append(dropzoneMessage);
        }

        dropzone.classList.toggle('dropzone-present', this.element.files && this.element.files.length > 0);
        this.element.addEventListener('change', () => {
            const filePresent = this.element.files && this.element.files.length > 0;
            dropzone.classList.toggle('dropzone-present', filePresent);
            if (filePresent && this.element.files.length === 1) {
                this._previewFile(dropzone, this.element.files[0]);
            } else {
                this._removePreview(dropzone);
            }
        });
        if (this.element.files && this.element.files.length > 0) {
            if (this.element.files.length === 1) {
                this._previewFile(dropzone, this.element.files[0]);
            }
        } else if (this.hasCurrentValue && this.currentValue) {
            this._previewFile(dropzone, this.currentValue);
        }

        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (event) {
            dropzone.addEventListener(event, function (e) {
                e.preventDefault();
                e.stopPropagation();
            });
        });

        dropzone.addEventListener('dragover', (e) => {
            if (!e.dataTransfer.types.includes('Files')) {
                console.warn("no file!", e.dataTransfer.types);
                return;
            }
            dropzone.classList.add('dropzone-dragging');
            if (this._acceptsFiles(e.dataTransfer.items)) {
                dropzoneMessage.textContent = i18n.t('js.file_input.drop_instruction');
            } else {
                dropzone.classList.add('dropzone-reject');
                const multiple = !!this.element.getAttribute('multiple');

                let invalidFileTypeCount = 0, containsAcceptedFile = false;
                if (multiple) {
                    Array.prototype.forEach.call(e.dataTransfer.items, file => {
                        if (this._acceptsFile(file)) {
                            containsAcceptedFile = true;
                        } else {
                            invalidFileTypeCount++;
                        }
                    });
                }
                if (!containsAcceptedFile) {
                    e.dataTransfer.dropEffect = 'none';
                }
                dropzoneMessage.textContent = containsAcceptedFile
                    ? i18n.t('js.file_input.not_all_files_accepted', {count: invalidFileTypeCount})
                    : i18n.t('js.file_input.files_not_accepted', {count: e.dataTransfer.items.length});
            }
        }, false);

        dropzone.addEventListener('dragleave', () => {
            dropzone.classList.remove('dropzone-dragging', 'dropzone-reject');
            dropzoneMessage.textContent = i18n.t('js.file_input.drag_drop_instruction');
        }, false);

        dropzone.addEventListener('drop', (e) => {
            dropzone.classList.remove('dropzone-dragging', 'dropzone-reject');
            const multiple = !!this.element.getAttribute('multiple');

            let filteredTransfer = new DataTransfer();
            Array.prototype.forEach.call(e.dataTransfer.items, item => {
                if (this._acceptsFile(item)) {
                    filteredTransfer.items.add(item.getAsFile());
                    if (!multiple) {
                        return false;
                    }
                }
            });
            if (filteredTransfer.items.length > 0) {
                this.element.files = filteredTransfer.files;
                this.element.dispatchEvent(new Event('change', {'bubbles': true}));
                if (!multiple) {
                    this._previewFile(dropzone, filteredTransfer.files[0]);
                }
                dropzoneMessage.textContent = i18n.t('js.file_input.drag_drop_instruction');
            } else {
                dropzone.classList.add('dropzone-reject');
                dropzoneMessage.textContent = i18n.t('js.file_input.files_not_accepted', {count: e.dataTransfer.items.length});
                setTimeout(() => {
                    if (!dropzone.classList.contains('dropzone-dragging')) {
                        dropzone.classList.remove('dropzone-reject');
                        dropzoneMessage.textContent = i18n.t('js.file_input.drag_drop_instruction');
                    }
                }, 1000);
            }
        }, false);
    }

    _acceptsFiles(files) {
        const multiple = !!this.element.getAttribute('multiple');
        if (multiple) {
            let accepted = true;
            Array.prototype.forEach.call(files, file => {
                if (!this._acceptsFile(file)) {
                    accepted = false;
                    return false;
                }
            });
            return accepted;
        } else {
            return this._acceptsFile(files[0]);
        }
    }

    _acceptsFile(transferItem) {
        if (transferItem.kind !== 'file') {
            return false;
        }
        let acceptedTypes = this.element.getAttribute('accept');
        if (!acceptedTypes || acceptedTypes.length === 0) {
            return true;
        }
        acceptedTypes = acceptedTypes.split(/[,|]/).map(v => v.toLowerCase());

        let file = transferItem.getAsFile();
        if (!file) {
            return true;
        }
        let fileType = fileExtensionRegex.exec(file.name);
        return fileType && acceptedTypes.includes(fileType[1].toLowerCase());
    }

    _previewFile(dropzone, file) {
        if (file == null || !((typeof file === "string") || (file.type && file.type.startsWith("image/")))) {
            this._removePreview(dropzone);
            return;
        }
        let image = dropzone.querySelector('img'),
            progress = dropzone.querySelector('.spinner-border');
        if (image == null) {
            image = document.createElement('img');
            image.className = 'd-none';
        }
        if (progress == null) {
            progress = document.createElement('section');
            progress.className = 'spinner-border text-leeo';
        }
        dropzone.classList.add('dropzone-preview')
        dropzone.append(image, progress);

        if (typeof file === "string") {
            image.onload = function () {
                image.classList.remove('d-none');
                progress.remove();
            }
            image.src = file;
        } else {
            const reader = new FileReader();
            reader.onloadend = function () {
                image.onload = function () {
                    image.classList.remove('d-none');
                    progress.remove();
                }
                image.src = reader.result;
            };
            reader.readAsDataURL(file);
        }
    }

    _removePreview(dropzone) {
        let image = dropzone.querySelector('img'),
            progress = dropzone.querySelector('spinner-border');
        if (image) {
            image.remove();
        }
        if (progress) {
            progress.remove();
        }
        dropzone.classList.remove('dropzone-preview');
    }
}
