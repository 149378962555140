import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n";

// Connects to data-controller="form-helper"
export default class extends Controller {
    static targets = ['autoFocus', 'visibilityToggle', 'enabledToggle', 'requiredInput', 'preventSubmitOnEnter']

    autoFocusTargetConnected(el) {
        el.focus();
    }

    visibilityToggleTargetConnected(el) {
        const targetQuery = el.dataset.visibilityTarget;
        if (targetQuery == null) {
            console.error("You must set data-visibility-target on ", el);
            return;
        }
        let targets = el.parentElement.querySelectorAll(targetQuery);
        if (targets == null || targets.length === 0) {
            targets = this.element.querySelectorAll(targetQuery);
        }
        if (targets == null || targets.length === 0) {
            targets = document.querySelectorAll(targetQuery);
        }
        if (targets && targets.length) {
            const toggleVisibility = (visible) => {
                targets.forEach(t => t.classList.toggle('d-none', !visible));
            };
            el.addEventListener('change', () => toggleVisibility(el.checked));
            toggleVisibility(el.checked);
        } else {
            console.error("Cannot find visibility target for '", targetQuery, "'");
        }
    }

    enabledToggleTargetConnected(el) {
        const targetQuery = el.dataset.enabledToggleTarget;
        if (targetQuery == null) {
            console.error("You must set data-enabled-toggle-target on ", el);
            return;
        }
        let targets = el.parentElement.querySelector(targetQuery);
        if (targets == null || targets.length === 0) {
            targets = document.querySelectorAll(targetQuery);
        }
        if (targets && targets.length) {
            const setEnabled = (enabled) => {
                targets.forEach(t => t.disabled = !enabled);
            };
            el.addEventListener('change', () => {
                setEnabled(el.checked);
            });
            setEnabled(el.checked);
        } else {
            console.error(`Cannot find enabled target for '${targetQuery}'`);
        }
    }

    requiredInputTargetConnected(el) {
        const changeHandler = (e) => {
            if (e.currentTarget.disabled || e.currentTarget.value.length > 0) {
                e.currentTarget.classList.remove('is-invalid');
                if (e.currentTarget.nextSibling && e.currentTarget.nextSibling.classList.contains('invalid-feedback')) {
                    e.currentTarget.nextSibling.remove();
                }
            } else {
                if (!e.currentTarget.nextSibling || !e.currentTarget.nextSibling.classList.contains('invalid-feedback')) {
                    let feedback = document.createElement('div');
                    feedback.classList.add('invalid-feedback');
                    feedback.innerText = i18n.t('errors.messages.blank');
                    e.currentTarget.after(feedback);
                }
                e.currentTarget.classList.add('is-invalid');
            }
        }

        el.addEventListener("change", changeHandler);
        el.addEventListener("input", changeHandler);
    }

    preventSubmitOnEnterTargetConnected(el) {
        el.addEventListener("click", (event) => {
            event.preventDefault();
            return false;
        });
        el.aria.hidden = true;
    }

    selectAll(ev) {
        ev.target.select();
    }

}
