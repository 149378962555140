import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n";

// Connects to data-controller="genetic-breeding-configuration"
export default class extends Controller {
    static targets = ['breedRegistrySequenceCard', 'skinColorConfigurationCard', 'teatQuantityConfigurationCard']

    initialize() {
        this.afterAdd = this.afterAdd.bind(this)
    }

    connect() {
        if (this.hasBreedRegistrySequenceCardTarget) {
            this.breedRegistrySequenceCardTargets.forEach(this.afterAdd);
        }
        if (this.hasSkinColorConfigurationCardTarget) {
            this.skinColorConfigurationCardTargets.forEach(this.afterAdd);
        }
        if (this.hasTeatQuantityConfigurationCardTarget) {
            this.teatQuantityConfigurationCardTargets.forEach(this.afterAdd);
        }
    }

    breedRegistrySequenceCardTargetConnected(el) {
        this.afterAdd(el);
    }

    skinColorConfigurationCardTargetConnected(el) {
        this.afterAdd(el);
    }

    teatQuantityConfigurationCardTargetConnected(el) {
        this.afterAdd(el);
    }

    afterAdd(nestedField) {
        let targetName = nestedField.dataset.geneticBreedingConfigurationTarget, enabledInput, descriptionUpdateMethod;
        if (targetName === 'breedRegistrySequenceCard') {
            enabledInput = nestedField.querySelector('.genetic_breeding_configuration_breed_registry_sequences_enabled input.custom-control-input');
            descriptionUpdateMethod = this._updateRowDescription;
        } else if (['skinColorConfigurationCard', 'teatQuantityConfigurationCard'].includes(targetName)) {
            enabledInput = nestedField.querySelector('.genetic_breeding_configuration_breeding_registration_configurations_enabled input.custom-control-input');
            descriptionUpdateMethod = this._updateBreedRegistryConfigurationRowDescription;
        }

        descriptionUpdateMethod.call(this, nestedField);
        nestedField.querySelectorAll('select, input').forEach(input => {
            input.addEventListener('input', () => descriptionUpdateMethod.call(this, nestedField));
        });

        enabledInput.addEventListener('change', ev => {
            nestedField.querySelector('.card-body.enabled_inputs').classList.toggle('d-none', !ev.target.checked);
        });
        nestedField.querySelector('.card-body.enabled_inputs').classList.toggle('d-none', !enabledInput.checked);
    }

    _updateBreedRegistryConfigurationRowDescription(nestedField) {
        let enabled = nestedField.querySelector('.genetic_breeding_configuration_breeding_registration_configurations_enabled input[type=checkbox]').checked,
            breed = nestedField.querySelector('.genetic_breeding_configuration_breeding_registration_configurations_breed select').selectedOptions[0].text,
            sex = nestedField.querySelector('.genetic_breeding_configuration_breeding_registration_configurations_sex input:checked').value,
            description = '';
        if (enabled) {
            description += breed;
            if (sex === 'female') description += `, ${i18n.t('sex.female')}`;
            if (sex === 'male') description += `, ${i18n.t('sex.male')}`;
        } else {
            description += i18n.t('disabled');
        }
        nestedField.querySelector('.description').innerHTML = description;
    }

    _updateRowDescription(nestedField) {
        let startCode = nestedField.querySelector('.genetic_breeding_configuration_breed_registry_sequences_start_code input').value,
            endCode = nestedField.querySelector('.genetic_breeding_configuration_breed_registry_sequences_end_code input').value,
            enabled = nestedField.querySelector('.genetic_breeding_configuration_breed_registry_sequences_enabled input[type=checkbox]').checked,
            breed = nestedField.querySelector('.genetic_breeding_configuration_breed_registry_sequences_breed select').selectedOptions[0].text,
            sex = nestedField.querySelector('.genetic_breeding_configuration_breed_registry_sequences_sex input:checked').value,
            lifeStatus = nestedField.querySelector('.genetic_breeding_configuration_breed_registry_sequences_life_status input:checked').value,
            description = '';
        if (startCode !== '' && endCode !== '') {
            description += `<code>${startCode}</code> - <code>${endCode}</code>: `;
        }
        if (enabled) {
            description += breed;
            if (sex === 'female') description += `, ${i18n.t('sex.female')}`;
            if (sex === 'male') description += `, ${i18n.t('sex.male')}`;
            if (lifeStatus === 'liveborn') description += `, ${i18n.t('views.genetic_breeding_configuration.liveborn')}`;
            if (lifeStatus === 'stillborn') description += `, ${i18n.t('views.genetic_breeding_configuration.stillborn')}`;
        } else {
            description += i18n.t('disabled');
        }
        nestedField.querySelector('.description').innerHTML = description;
    };

    _updatePosition(nestedField) {
        nestedField.querySelector('span.position-label').innerHTML = nestedField.querySelector('input[id*="_position"]').value;
    }
}
