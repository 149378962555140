import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="transport-plan-selection-rule"
export default class extends Controller {
    static targets = ['weightFilterEnabledInput', 'weightFilterComparatorInput', 'weightFilterValueInput',
        'ageFilterEnabledInput', 'ageFilterComparatorInput', 'ageFilterValueInput',
        'parityFilterEnabledInput', 'parityFilterComparatorInput', 'parityFilterValueInput',
        'cullReasonFilterEnabledInput', 'cullReasonFilterSearchInput', 'cullReasonTemplate',
        'selectedCullReasonsWrapper', 'selectedCullReason', 'breedFilterEnabledInput', 'breedFilterSearchInput',
        'breedTemplate', 'selectedBreedsWrapper', 'selectedBreed', 'locationFilterEnabledInput',
        'locationFilterSearchInput', 'barnTemplate', 'roomTemplate', 'penTemplate',
        'selectedLocationsWrapper', 'selectedBarn', 'selectedRoom', 'selectedPen']

    static values = {
        unitOfMeasurement: {type: String, default: "metric"}
    }

    connect() {
    }

    configure(options) {
        if (options.weight) {
            this.setWeightFilter(options.weight.value[this.unitOfMeasurementValue], options.weight.comparator);
        } else {
            this.setWeightFilter(false);
        }
        if (options.age) {
            this.setAgeFilter(options.age.value, options.age.comparator);
        } else {
            this.setAgeFilter(false);
        }
        if (options.parity) {
            this.setParityFilter(options.parity.value, options.parity.comparator);
        } else {
            this.setParityFilter(false);
        }
        if (options.cullReason) {
            this.setCullReasonFilter(true);
        } else {
            this.setCullReasonFilter(false);
        }
        if (options.breed) {
            this.setBreedFilter(true);
        } else {
            this.setBreedFilter(false);
        }
        if (options.location) {
            this.setLocationFilter(true);
        } else {
            this.setLocationFilter(false);
        }
    }

    cullReasonFilterSearchInputTargetConnected(el) {
        el.addEventListener("selection", (e) => {
            const selection = e.detail.selection.value;
            this._addAutocompleteSelection(
                selection,
                this.selectedCullReasonTargets,
                this.cullReasonTemplateTarget,
                this.selectedCullReasonsWrapperTarget
            );

            // Reset search field
            e.target.value = null;
        });
    }

    breedFilterSearchInputTargetConnected(el) {
        el.addEventListener("selection", (e) => {
            const selection = e.detail.selection.value;
            this._addAutocompleteSelection(
                selection,
                this.selectedBreedTargets,
                this.breedTemplateTarget,
                this.selectedBreedsWrapperTarget,
                'label'
            );

            // Reset search field
            e.target.value = null;
        });
    }

    locationFilterSearchInputTargetConnected(el) {
        el.addEventListener('selection', (e) => {
            const selection = e.detail.selection.value;
            switch (selection._type) {
                case 'barn':
                    this._addAutocompleteSelection(
                        selection,
                        this.selectedBarnTargets,
                        this.barnTemplateTarget,
                        this.selectedLocationsWrapperTarget
                    );
                    break;
                case 'room':
                    this._addAutocompleteSelection(
                        selection,
                        this.selectedRoomTargets,
                        this.roomTemplateTarget,
                        this.selectedLocationsWrapperTarget
                    );
                    break;
                case 'pen':
                    this._addAutocompleteSelection(
                        selection,
                        this.selectedPenTargets,
                        this.penTemplateTarget,
                        this.selectedLocationsWrapperTarget
                    );
                    break;
                default:
                    console.log(`Location type not implemented: '${selection.type}'`)
                    break;
            }

            // Reset search field
            e.target.value = null;
        });
    }

    toggleSelection(ev) {
        const badge = ev.target, idInput = badge.querySelector('input'),
            enable = idInput.disabled;
        badge.classList.toggle('opacity-50', !enable);
        idInput.disabled = !enable;
        idInput.dispatchEvent(new Event("change", {bubbles: true, cancelable: false}));
    }

    removeSelection(ev) {
        ev.target.closest('.badge').remove();
    }

    decreaseWeightByTen() {
        this.adjustWeight(-10);
    }

    decreaseWeightByFive() {
        this.adjustWeight(-5);
    }

    increaseWeightByFive() {
        this.adjustWeight(5);
    }

    increaseWeightByTen() {
        this.adjustWeight(10);
    }

    adjustWeight(by) {
        const currentWeight = parseInt(this.weightFilterValueInputTarget.value),
            newWeight = Math.min(Math.max(currentWeight + by, this.weightFilterValueInputTarget.min), this.weightFilterValueInputTarget.max);
        if (currentWeight !== newWeight) {
            this.weightFilterValueInputTarget.value = newWeight;
            this.weightFilterValueInputTarget.dispatchEvent(new Event('change', {'bubbles': true}));
        }
    }

    decreaseAgeByTen() {
        this.adjustAge(-10);
    }

    decreaseAgeByFive() {
        this.adjustAge(-5);
    }

    increaseAgeByFive() {
        this.adjustAge(5);
    }

    increaseAgeByTen() {
        this.adjustAge(10);
    }

    adjustAge(by) {
        const currentAge = parseInt(this.ageFilterValueInputTarget.value),
            newAge = Math.min(Math.max(currentAge + by, this.ageFilterValueInputTarget.min), this.ageFilterValueInputTarget.max);
        if (currentAge !== newAge) {
            this.ageFilterValueInputTarget.value = newAge;
            this.ageFilterValueInputTarget.dispatchEvent(new Event('change', {'bubbles': true}));
        }
    }

    setWeightFilter(value, comparator) {
        if (this.hasWeightFilterEnabledInputTarget) {
            this._setNumericFilter(
                this.weightFilterEnabledInputTarget,
                this.weightFilterComparatorInputTarget,
                this.weightFilterValueInputTarget,
                value,
                comparator
            );
        }
    }

    setAgeFilter(value, comparator) {
        if (this.hasAgeFilterEnabledInputTarget) {
            this._setNumericFilter(
                this.ageFilterEnabledInputTarget,
                this.ageFilterComparatorInputTarget,
                this.ageFilterValueInputTarget,
                value,
                comparator
            );
        }
    }

    setParityFilter(value, comparator) {
        if (this.hasParityFilterEnabledInputTarget) {
            this._setNumericFilter(
                this.parityFilterEnabledInputTarget,
                this.parityFilterComparatorInputTarget,
                this.parityFilterValueInputTarget,
                value,
                comparator
            );
        }
    }

    setCullReasonFilter(enabled) {
        if (this.hasCullReasonFilterEnabledInputTarget) {
            this._setFilterEnabled(this.cullReasonFilterEnabledInputTarget, enabled);
        }
    }

    setBreedFilter(enabled) {
        if (this.hasBreedFilterEnabledInputTarget) {
            this._setFilterEnabled(this.breedFilterEnabledInputTarget, enabled);
        }
    }

    setLocationFilter(enabled) {
        if (this.hasLocationFilterEnabledInputTarget) {
            this._setFilterEnabled(this.locationFilterEnabledInputTarget, enabled);
        }
    }


    _setNumericFilter(enabledInput, comparatorInput, valueInput, value, comparator) {
        const filterEnabled = value !== false;
        if (filterEnabled) {
            comparatorInput.value = comparator ?? "Greater than"; // TODO proper comparator value
            valueInput.value = value;
        }
        this._setFilterEnabled(enabledInput, filterEnabled);
    }

    _setFilterEnabled(switchInput, enable) {
        if (switchInput.checked !== enable) {
            switchInput.checked = enable;
            switchInput.dispatchEvent(new Event('change', {'bubbles': true}))
        }
    }

    _addAutocompleteSelection(selection, existingTargets, templateTarget, wrapperTarget, nameMethod) {
        const existingNode = existingTargets.find((t) => t.dataset.selectionId === selection.id);
        if (existingNode) {
            const idInput = existingNode.querySelector('input');
            if (idInput.disabled) {
                this.toggleSelection(existingNode);
            } else {
                // TODO animate to show selection
            }
        } else {
            const newNode = templateTarget.content.cloneNode(true),
                badge = newNode.firstElementChild, idInput = newNode.querySelector('input');
            wrapperTarget.appendChild(newNode);

            idInput.value = selection.id;
            badge.dataset.selectionId = selection.id;
            badge.prepend(selection[nameMethod || 'name']);
        }
    }
}
