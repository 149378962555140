import AutocompleteSource from './autocomplete_source'

export default class ObservationAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('observations', {keys: ['name'], ...options});
    }

    styleOptionNode(node, data) {
        if (data.value && data.value.exists) {
            node.classList.add('checked');
        }
        node.classList.add('d-flex', 'align-items-center', 'flex-wrap');
        node.innerHTML = `<span class="text-truncate flex-fill">${data.match}</span>`;
        if (data.value.processes) {
            data.value.processes.forEach((process) => {
                const icon = document.createElement("i");
                icon.classList.add('fas', 'fa-' + process.icon, 'ml-1');
                icon.title = process.title;
                node.append(icon);
            });
        }
    }
}