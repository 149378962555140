import Fuse from "fuse.js";

const SelectColumns = {};

SelectColumns.initialize = function (configuration) {
    let $wrapper = $(configuration.wrapper || '#select_pig_columns'),
        $modal = $($wrapper.find('a[href="#select-columns"]').data('target') || '#select_columns_modal'),
        $lastChangedItem, consecutiveItemsSelected = 0, fuse,
        setColumnsSelected = function (whether, category) {
            let $columnItems = $modal.find('.column_item:visible'),
                checkCssSelector = whether ? ':not(:checked)' : ':checked';
            if (category && category.length) {
                $columnItems = $columnItems.filter('[data-category="' + category + '"]');
            }
            $columnItems.each(function () {
                $wrapper.find(':checkbox[data-column=' + $(this).data('column') + ']' + checkCssSelector).prop('checked', whether).trigger('change');
            });
        };

    $modal.find('button.select_all_columns').on('click', function () {
        setColumnsSelected(true, $(this).data('category'));
    });
    $modal.find('button.deselect_all_columns').on('click', function () {
        setColumnsSelected(false, $(this).data('category'));
    });

    $modal.find('.column_item').on('click', function (e) {
        let $this = $(this), column = $this.data('column'),
            $checkbox = $wrapper.find(':checkbox[data-column=' + column + ']'),
            check = !$checkbox.prop('checked');

        if (e.shiftKey && $lastChangedItem) {
            if ($this.index() > $lastChangedItem.index()) {
                $lastChangedItem.nextUntil($this).addBack().each(function () {
                    $wrapper.find(':checkbox[data-column=' + $(this).data('column') + ']').prop('checked', check).trigger('change');
                });
            } else if ($this.index() < $lastChangedItem.index()) {
                $lastChangedItem.prevUntil($this).addBack().each(function () {
                    $wrapper.find(':checkbox[data-column=' + $(this).data('column') + ']').prop('checked', check).trigger('change');
                });
            }
            if (consecutiveItemsSelected >= 4 && configuration.localization.holdShiftReward) {
                Snackbar.close().show({
                    text: configuration.localization.holdShiftReward, duration: 1000
                });
            }
        }
        if (configuration.localization.holdShiftTip && !e.shiftKey && $lastChangedItem && ($lastChangedItem.prev().is($this) || $lastChangedItem.next().is($this))) {
            consecutiveItemsSelected++;
            if (consecutiveItemsSelected % 4 === 0 && Snackbar.current == null) {
                Snackbar.show({
                    text: configuration.localization.holdShiftTip, duration: 10000
                });
            }
        } else {
            consecutiveItemsSelected = 0;
        }
        $lastChangedItem = $this;

        $checkbox.prop('checked', check).trigger('change');
        e.preventDefault();
    });

    $modal.find('input.column_search').on('input search', $.debounce(50, function () {
        let filter = $(this).val();
        if (filter.length === 0) {
            $modal.find('.column_category_header, .select_all_columns, .deselect_all_columns, .column_item').removeClass('d-none');
            $modal.find('.nothing_found_text').addClass('d-none');
            return;
        }

        let result = fuse.search(filter), foundAnything = false, $currentItem;
        $modal.find('.column_item').addClass('d-none').each(function () {
            $currentItem = $(this)
            if (result.includes($currentItem.attr('data-column'))) {
                $currentItem.removeClass('d-none');
                foundAnything = true;
            }
        });

        $modal.find('.column_category_header, .select_all_columns[data-category], .deselect_all_columns[data-category]').each(function () {
            let $this = $(this), category = $this.data('category');
            if (category && foundAnything && $modal.find('.column_item:visible[data-category="' + category + '"]').length) {
                $this.removeClass('d-none');
            } else {
                $this.addClass('d-none');
            }
        });

        if (foundAnything) {
            $modal.find('.nothing_found_text').addClass('d-none');
        } else {
            $modal.find('.nothing_found_text').removeClass('d-none');
        }
    })).on('keydown', function (e) {
        if (e.keyCode === 13) {
            // Return
            $($modal.find('.column_item:visible:not(.disabled)').get(0)).click();
            e.preventDefault();
            return false;
        }
    });

    $modal.find('.column_search, .column_item').on('keydown', function (e) {
        if (e.keyCode === 38) {
            // Up
            let $prev = $(this).prev('.column_item, .column_category_header, .btn-bar');
            while ($prev.length && !$prev.is('.column_item:visible')) {
                $prev = $prev.prev('.column_item, .column_category_header, .btn-bar');
            }
            if ($prev.length) {
                $prev.focus();
            } else {
                $modal.find('.column_search').focus();
            }
            e.preventDefault();
            return false;
        } else if (e.keyCode === 40) {
            // Down
            if ($(this).hasClass('column_search')) {
                $($modal.find('.column_item:visible').get(0)).focus();
            } else {
                let $next = $(this).next('.column_item, .column_category_header, .btn-bar');
                while ($next.length && !$next.is('.column_item:visible')) {
                    $next = $next.next('.column_item, .column_category_header, .btn-bar');
                }
                $next.focus();
            }
            e.preventDefault();
            return false;
        }
    });
    $modal.find('.column_item').on('keydown', function (e) {
        if (e.keyCode === 8) {
            // Backspace
            $modal.find('.column_search').focus();
        }
    });

    $modal.on('show.bs.modal', function () {
        if (fuse == null) {
            fuse = SelectColumns._createFuse($modal);
        }

        $modal.find('.column_search').val('').trigger('search');
        $modal.find('.column_item').each(function () {
            let $modalItem = $(this), columnId = $modalItem.data('column'),
                $checkbox = $wrapper.find(':checkbox[data-column=' + columnId + ']');
            if ($checkbox.length === 0) {
                $modalItem.addClass('d-none');
                console.error('Whoops!  ‘' + columnId + '’ checkbox not found');
            } else {
                SelectColumns._showItemAdded($checkbox, $modalItem)
            }
        });
    }).on('shown.bs.modal', function () {
        $modal.find('.column_search').focus();
    });

    $wrapper.find(':checkbox[data-column]').on('change', function () {
        SelectColumns._showItemAdded($(this), $modal);
    });
};

SelectColumns._showItemAdded = function ($checkbox, $itemOrModal) {
    let added = $checkbox.is(':checked'),
        columnId = $checkbox.data('column'),
        $modalItem;
    if ($itemOrModal.hasClass('column_item') && $itemOrModal.data('column') === columnId) {
        $modalItem = $itemOrModal;
    } else {
        $modalItem = $itemOrModal.find('.column_item[data-column=' + columnId + ']');
    }
    if ($modalItem.length === 0) {
        console.error('Whoops!  ‘' + columnId + '’ modal item not found');
    } else {
        $modalItem.find('.icon_unadded').toggle(!added);
        $modalItem.find('.icon_added').toggle(added);
    }
};

SelectColumns._createFuse = function ($modal) {
    let fuseItems = [],
        fuseOptions = {
            id: 'id',
            shouldSort: true,
            threshold: 0.25,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            tokenize: true,
            tokenSeparator: /\s/,
            matchAllTokens: true,
            keys: [{
                name: 'title',
                weight: 0.7
            }, {
                name: 'description',
                weight: 0.1
            }, {
                name: 'keywords',
                weight: 0.2
            }]
        };

    $modal.find('a.column_item').each(function () {
        let keywords = $(this).attr('data-keywords'), keywordList = null;
        if (keywords) {
            keywordList = []
            keywords.split(',').forEach(function (item) {
                if (item.length) {
                    keywordList.push(item);
                }
            });
        }
        fuseItems.push({
            id: $(this).attr('data-column'),
            title: $(this).find('.column_title').text(),
            description: $(this).find('.column_description').text(),
            keywords: keywordList
        });
    });
    return new Fuse(fuseItems, fuseOptions);
};

module.exports = SelectColumns;