import {Controller} from "@hotwired/stimulus"
import dayjs from "dayjs";

const pattern = /^(2[0-3]|[0-1]?[0-9])(?:(:?[0-5]?[0-9])(?::([0-5]?[0-9])(\.[0-9]{1,6})?)?)?\s*([ap]m?)?$/i;

// Connects to data-controller="time-of-day-input"
export default class extends Controller {
    static values = {dateInputSelector: String}

    connect() {
        this.inputListener = () => {
            let timeOfDay = this._parseTimeOfDay();
            if (timeOfDay) {
                this.element.classList.remove("is-invalid")
            } else if (this.element.value.length) {
                this.element.classList.add("is-invalid")
            }
        }
        this.blurListener = () => {
            let timeOfDay = this._parseTimeOfDay();
            if (timeOfDay) {
                this.element.classList.remove("is-invalid")
                // Format the value
                this.setValue(timeOfDay.hours, timeOfDay.minutes, timeOfDay.meridiem, timeOfDay.seconds, timeOfDay.microseconds);
            } else if (this.element.value.length) {
                this.element.classList.add("is-invalid")
            }
        }
        this.arrowListener = (ev) => {
            let adjustment;
            if (ev.keyCode === 38) {
                // up-arrow
                adjustment = 1;
                ev.preventDefault();
            } else if (ev.keyCode === 40) {
                // down-arrow
                adjustment = -1;
                ev.preventDefault();
            }
            if (adjustment) {
                this._adjustValue(adjustment, ev.ctrlKey || ev.metaKey || ev.shiftKey, ev.altKey);
            }
        }

        this.element.addEventListener("keydown", this.arrowListener)
        this.element.addEventListener("input", this.inputListener)
        this.element.addEventListener("blur", this.blurListener)
    }

    disconnect() {
        this.element.removeEventListener("keydown", this.arrowListener)
        this.element.removeEventListener("input", this.inputListener)
        this.element.removeEventListener("blur", this.blurListener)
    }

    setValue(hours, minutes, meridiem, seconds, microseconds) {
        if (hours != null) {
            let hourCycles = new Intl.Locale(navigator.language)?.getHourCycles(),
                newValue = hours.toString();
            if (minutes != null) {
                newValue += `:${minutes.toString().padStart(2, '0')}`;
                if (seconds || microseconds) {
                    newValue += `:${seconds.toString().padStart(2, '0')}`;
                    if (microseconds) {
                        newValue += `.${microseconds.toString().padStart(6, '0').replace(/0+$/, '')}`;
                    }
                }
            }
            if (meridiem && hours < 13) {
                if (meridiem === 'A') {
                    newValue += ' am'
                } else if (meridiem === 'P') {
                    newValue += ' pm'
                } else {
                    newValue += ` ${meridiem}`
                }
            }
            if (minutes == null && meridiem == null) {
                if (hours < 13 && hourCycles && !hourCycles.includes("h23")) {
                    newValue += ' am';
                } else {
                    newValue += ':00';
                }
            }
            if (this.element.value.localeCompare(newValue) !== 0) {
                this.element.value = newValue;
                this.element.dispatchEvent(new Event("change", {bubbles: true}));
            }
        } else {
            this.element.value = "";
        }
    }

    _parseTimeOfDay() {
        let value = this.element.value;
        if (value.endsWith(":") && value.split(":") <= 3) {
            value = value.substring(0, value.length - 1);
        }
        let match = pattern.exec(value.trim());
        if (match) {
            let hours = parseInt(match[1]), minutes, seconds, microseconds, meridiem;
            if (match[2]) {
                let minutesString = match[2].replace(/^:/, "");
                minutes = parseInt(minutesString);
                if (minutesString.length === 1) {
                    if (!match[2].includes(":") && (hours >= 20 || (hours >= 10 && hours < 16) || (hours < 6 && match[1].startsWith("0")))) {
                        minutes += hours % 10 * 10;
                        hours = Math.floor(hours / 10);
                    } else if (minutes < 6 && minutesString.length === 1) {
                        minutes = minutes * 10;
                    }
                }
                if (match[3]) {
                    // Require minutes to be fully specified (":12")
                    if (match[2].length != 3) {
                        return null;
                    }

                    seconds = parseInt(match[3]);
                    if (match[4]) {
                        microseconds = parseInt(match[4].substring(1).padEnd(6, '0'));
                    }
                }
            }
            if (match[5]) {
                meridiem = match[5].substring(0, 1).toUpperCase();
            }
            return {hours: hours, minutes: minutes, seconds: seconds, microseconds: microseconds, meridiem: meridiem};
        } else {
            return null;
        }
    }

    _adjustValue(adjustment, forceHours, forcePreciseMinutes) {
        let timeOfDay = this._parseTimeOfDay(), oldHours = timeOfDay?.hours;
        if (oldHours == null) {
            if (this.element.value.length > 0) {
                this.element.classList.add("is-invalid")
            }
            return
        }

        this.element.classList.remove("is-invalid")
        if (timeOfDay.minutes == null || forceHours) {
            // Change hours
            timeOfDay.hours += adjustment;
        } else {
            // Change minutes
            if (forcePreciseMinutes) {
                timeOfDay.minutes += adjustment;
            } else if (timeOfDay.minutes % 15 === 0) {
                timeOfDay.minutes += 15 * adjustment;
            } else if (timeOfDay.minutes % 10 === 0) {
                timeOfDay.minutes += 10 * adjustment;
            } else {
                // Round to 15 minutes
                if (adjustment > 0) {
                    timeOfDay.minutes = Math.ceil(timeOfDay.minutes / 15.0) * 15;
                } else {
                    timeOfDay.minutes = Math.floor(timeOfDay.minutes / 15.0) * 15;
                }
            }
        }
        if (timeOfDay.minutes >= 60) {
            timeOfDay.hours += 1;
            timeOfDay.minutes -= 60;
        } else if (timeOfDay.minutes < 0) {
            timeOfDay.hours -= 1;
            timeOfDay.minutes += 60;
        }
        if (timeOfDay.hours >= 24) {
            timeOfDay.hours -= 24;
            timeOfDay.date = +1;
        } else if (timeOfDay.hours < 0) {
            timeOfDay.hours += 24;
            timeOfDay.date = -1;
        }
        if (timeOfDay.meridiem) {
            let hourCycles = new Intl.Locale(navigator.language)?.getHourCycles();
            if (Math.min(oldHours, timeOfDay.hours) < 12 && Math.max(oldHours, timeOfDay.hours) >= 12) {
                // We've crossed noon / midnight
                if (timeOfDay.meridiem === 'P' && timeOfDay.hours > oldHours) {
                    timeOfDay.date = +1;
                } else if (timeOfDay.meridiem === 'A' && timeOfDay.hours < oldHours) {
                    timeOfDay.date = -1;
                }
                timeOfDay.meridiem = (oldHours > 13 || timeOfDay.meridiem === 'P' ? 'A' : 'P');
                if (timeOfDay.hours > 12 || (timeOfDay.hours > 11 && hourCycles && hourCycles[0] === "h11")) {
                    timeOfDay.hours -= 12;
                } else if (timeOfDay.hours === 0) {
                    timeOfDay.hours += 12;
                }
            } else if (timeOfDay.hours > 12) {
                // Changed from 12 pm to 1 pm
                timeOfDay.hours -= 12;
            } else if (timeOfDay.hours === 0 && hourCycles && hourCycles[0] !== "h11") {
                // Changed from 1 pm to 12 pm
                timeOfDay.hours += 12;
            }
        }

        this.setValue(timeOfDay.hours, timeOfDay.minutes, timeOfDay.meridiem, timeOfDay.seconds, timeOfDay.microseconds);
        if (timeOfDay.date && this.hasDateInputSelectorValue) {
            this._adjustDate(timeOfDay.date);
        }
    }

    _adjustDate(days) {
        if (!this.hasDateInputSelectorValue) {
            return
        }

        let inputElement = document.querySelector(this.dateInputSelectorValue);
        if (inputElement == null) {
            console.error(`[TimeOfDayInput] Cannot find date input for selector "${this.dateInputSelectorValue}"`)
            return;
        }

        let date = dayjs(inputElement.value);
        date.add(days, 'day');
        inputElement.value = date.format();
        inputElement.dispatchEvent(new Event("change", {bubbles: true}));
    }
}
