import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="set-inputs"
export default class extends Controller {
    static targets = ["settable"]

    execute({ params: { value }}) {
        event.preventDefault()
        this.settableTargets.forEach((element, index) => {
            if (element.getAttribute('type') === 'radio') {
                element.checked = element.value === value[0]
            } else if (element.getAttribute('type') === 'checkbox') {
                element.checked = value[index] === 'true'
            } else {
                element.value = value[index]
            }
        })
    }
}