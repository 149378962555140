import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["eventInput", "optionsHeader", "optionsWrapper", "optionsSection"];

    connect() {
        super.connect();
        // Init form
        this.onEventChanged();
    }

    onEventChanged() {
        this.optionsSectionTargets.forEach(el => {
            this._setSectionVisibility(el, this.eventInputTarget.value);
        });
        if (this.optionsWrapperTarget.querySelector("input:not([disabled]), select:not([disabled])")) {
            this.optionsHeaderTarget.classList.remove('d-none');
        } else {
            this.optionsHeaderTarget.classList.add('d-none');
        }
    }

    _setSectionVisibility(section, eventType) {
        if (!section.dataset.applicableTo) {
            return;
        }

        if (section.dataset.applicableTo && section.dataset.applicableTo.split(" ").includes(eventType)) {
            section.classList.remove("d-none");
            section.querySelectorAll("input, select").forEach(input => input.disabled = false);
        } else {
            section.classList.add("d-none");
            section.querySelectorAll("input, select").forEach(input => input.disabled = true);
        }
    }

}