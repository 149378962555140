import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="revision-wizard"
export default class extends Controller {
    static targets = ['customerInput', 'selectResource', 'clearResources', 'confirmationForm',
        'selectActionUpdateToggle', 'selectActionUndoToggle', 'selectActionTabs', 'selectActionInstructionTab',
        'selectActionUpdateTab', 'selectActionUndoTab', 'timeZoneInput']
    static values = {
        selectedResourceCount: Number
    }

    connect() {
        if (this.hasTimeZoneInputTarget && this.hasSelectActionUpdateToggleTarget && this.hasSelectActionUpdateTabTarget) {
            const observer = new MutationObserver((mutationsList) => {
                for (const mutation of mutationsList) {
                    if(this.selectActionUpdateToggleTarget.checked){
                        if (mutation.attributeName === 'disabled' && this.timeZoneInputTargets.some(el => el === mutation.target)) {
                            this._setConfirmationEnabled(this.timeZoneInputTargets.every(el => el.disabled || el.closest('.d-none') || (el.value && el.value.length > 0)));
                        } else if (mutation.attributeName === 'class' && mutation.target.querySelector('input[id*=time_zone_id]')) {
                            this._setConfirmationEnabled(this.timeZoneInputTargets.every(el => el.disabled || el.closest('.d-none') || (el.value && el.value.length > 0)));
                        }
                    }

                }
            });
            observer.observe(this.selectActionUpdateTabTarget, {
                attributes: true,
                subtree: true,
                childList: true,
                attributeFilter: ['disabled', 'class']
            });
        }
    }

    customerInputTargetConnected(el) {
        el.addEventListener('change', () => {
            this._setConfirmationEnabled(el.value && el.value.length > 0);
        });
    }

    selectResourceTargetConnected(el) {
        el.addEventListener('change', () => {
            if (el.checked) {
                this.selectedResourceCountValue += 1;
            } else {
                this.selectedResourceCountValue -= 1;
            }
            this._setConfirmationEnabled(this.selectedResourceCountValue > 0);
        });
    }

    clearResourcesTargetConnected(el) {
        el.addEventListener('click', () => {
            this.selectResourceTargets.forEach(input => {
                input.checked = false;
                input.dispatchEvent(new Event('change', {'bubbles': true}));
            });
            this.selectedResourceCountValue = 0;
            this._setConfirmationEnabled(false);
        });
    }

    selectActionUpdateToggleTargetConnected(el) {
        el.addEventListener('change', () => {
            if (el.checked) {
                this._showSelectActionTab(this.selectActionUpdateTabTarget);
                this._setConfirmationEnabled(true);
            }
        });
    }

    selectActionUndoToggleTargetConnected(el) {
        el.addEventListener('change', () => {
            if (el.checked) {
                this._showSelectActionTab(this.selectActionUndoTabTarget);
                this._setConfirmationEnabled(true);
            }
        });
    }

    timeZoneInputTargetConnected(el) {
        el.addEventListener('change', () => {
            this._setConfirmationEnabled(this.timeZoneInputTargets.every((target) => target.disabled || el.closest('.d-none') || target.value.length > 0));
        });
    }

    _setConfirmationEnabled(whether) {
        const disabled = !whether;
        this.confirmationFormTarget.querySelectorAll('input[type=submit],button[type=submit]').forEach(submitButton => {
            submitButton.disabled = disabled;
            submitButton.classList.toggle('disabled', disabled);
        });
    }

    _showSelectActionTab(tab) {
        for (const otherTab of this.selectActionTabsTarget.children) {
            if (otherTab.classList.contains('active')) {
                otherTab.setAttribute('aria-selected', false);
                otherTab.classList.remove('show');
                setTimeout(() => otherTab.classList.remove('active'), 150);
            }
        }
        if (!tab.classList.contains('active')) {
            tab.setAttribute('aria-selected', true);
            tab.classList.add('fade', 'show');
            setTimeout(() => tab.classList.add('active'), 150);
        }
    }

}
