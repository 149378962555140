import {Controller} from "@hotwired/stimulus";
import consumer from '../channels/consumer';
import WizardProgressBar from "../wizard_progress_bar";

const POLL_INTERVAL = 15000;

export default class extends Controller {
    static targets = ['customerInput', 'fileInput', 'confirmationForm', "progressBar", "itemsProcessed"];

    connect() {
        if (this.hasProgressBarTarget || this.hasItemsProcessedTarget) {
            this.subscription = consumer.subscriptions.create(
                {
                    channel: "ImportStatusChannel",
                    id: this.data.get("id"),
                },
                {
                    received: this._received.bind(this),
                }
            );
            if ((this.data.get("status") === 'pre_flight' || this.data.get("status") === 'importing') && this.data.get("path")) {
                // Poll for status in case the websocket fails
                this.interval = setInterval(this._pollData.bind(this), POLL_INTERVAL);
            }
        }
    }

    disconnect() {
        super.disconnect();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    customerInputTargetConnected(el) {
        el.addEventListener('change', () => {
            this._setConfirmationEnabled(el.value && el.value.length > 0);
        });
    }

    fileInputTargetConnected(el) {
        el.addEventListener('change', () => {
            this._setConfirmationEnabled(el.value && el.value.length > 0);
        });
    }

    _setConfirmationEnabled(whether) {
        const disabled = !whether;
        this.confirmationFormTarget
            .querySelectorAll('input[type=submit],button[type=submit]')
            .forEach(submitButton => {
                submitButton.disabled = disabled;
                submitButton.classList.toggle('disabled', disabled);
            });
    }

    _pollData() {
        if (this.lastDataReceivedAt && Date.now() - this.lastDataReceivedAt < POLL_INTERVAL * 0.9) {
            // Websocket seems to be active
            return;
        }

        fetch(this.data.get("path"))
            .then(response => response.json())
            .then(data => this._received(data))
            .catch(error => console.error('Error fetching export data:', error));
    }

    _received(data) {
        this.lastDataReceivedAt = Date.now();
        if (this.data.get("status") !== data.status) {
            clearInterval(this.interval);
            Turbo.visit(location, {action: 'replace'});
        } else if (data.status === 'pre_flight' || data.status === 'importing') {
            WizardProgressBar.update(this, data);
        }
    }
}